<script lang="ts">
  /**
 *
 *
 *
 *
 */
  /*                                            */
  import { tick } from "svelte";
  /*                                            */
  import { on } from "svelte/events";

  interface Props {
    /**
 *
 */
    asButton?: boolean;
    /**
 *
 */
    href?: string;
    /**
 *
 *
 */
    base64Href?: string | undefined;
    /**
 *
 */
    lightDomLink?: HTMLElement;
    /**
 *
 */
    interactiveClass?: string | undefined;
    thisElement?: HTMLElement | undefined;
    children?: import("svelte").Snippet;
    [key: string]: unknown;
  }

  let {
    asButton = false,
    href = $bindable(undefined),
    base64Href = $bindable(undefined),
    interactiveClass = undefined,
    thisElement = $bindable(undefined),
    lightDomLink,
    children,
    ...rest
  }: Props = $props();

  /**
 *
 *
 */
  const unmaskHref = async (ev: Event) => {
    if (!base64Href) return;

    let decodedHref = atob(base64Href);
    base64Href = undefined;

    if (lightDomLink) {
      if (lightDomLink instanceof HTMLAnchorElement) {
        lightDomLink.href = decodedHref;
      } else {
        /*                                                     */
        /*                                                                                  */
        const anchorElement = document.createElement("a");

        /*                                                          */
        Array.from(lightDomLink.attributes).forEach(({ name, value }) =>
          anchorElement.setAttribute(name, value),
        );

        anchorElement.href = decodedHref;

        /*                                                           */
        anchorElement.append(...lightDomLink.childNodes);

        /*                                           */
        lightDomLink.parentNode?.replaceChild(anchorElement, lightDomLink);

        /*                                  */
        if (ev instanceof FocusEvent) {
          /*                    */
          /*                                                     */
          anchorElement.focus();
        }
      }
    } else {
      /*                                                         */

      /*                                                      */
      href = decodedHref;

      /*                                  */
      if (ev instanceof FocusEvent) {
        /*                                     */
        await tick();
        /*                    */
        /*                                                     */
        thisElement?.focus();
      }
    }
  };

  $effect(() => {
    if (!base64Href) return;

    /*                                           */
    const removeListenerList = ["focusin", "mouseover", "touchstart"].map((ev) =>
      on(thisElement!, ev, unmaskHref),
    );

    /*                                         */
    return () => removeListenerList.forEach((cb) => cb());
  });

  /*                                                               */
  $effect(() => {
    if (thisElement) (thisElement as HTMLAnchorElement).href = href ?? "";
  });

  /*               */
  const tag = $derived.by(() => {
    if (lightDomLink || base64Href) return "div";
    if (href) return "a";
    if (asButton) return "button";
    return "div";
  });

  /*                                                                             */
  const tabindex = $derived(!lightDomLink && base64Href ? 0 : undefined);
  const role = $derived(!lightDomLink && base64Href ? "link" : undefined);

  /*                                  */
  const isInteractive = $derived(
    tabindex === 0 || rest?.tabIndex === 0 || thisElement?.tabIndex === 0,
  );

  /*                                                                        */
  const className = $derived(`${rest.class ?? ""} ${(isInteractive && interactiveClass) || ""}`);
</script>

<!--order of props is important-->
<!--...$$restProps as late as possible to allow overwriting-->
<!--...$$restProps before class otherwise $$restProps.class overwrites class-->
<svelte:element this={tag} bind:this={thisElement} {tabindex} {role} {...rest} class={className}>
  {@render children?.()}
</svelte:element>

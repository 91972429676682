<svelte:options
  customElement={{
    tag: "oc-row-v2",
    shadow: "none",
    /*                       */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      variant: { type: "String" },
      hideDivider: { type: "Boolean", attribute: "hide-divider" },
      asButton: { type: "Boolean", attribute: "as-button" },
      selected: { type: "Boolean" },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      iconLeft: { type: "String", attribute: "icon-left" },
      iconRight: { type: "String", attribute: "icon-right" },
    },
  }}
/>

<script lang="ts">
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";
  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import type { Props } from "./RowV2.types.js";

  let {
    variant,
    hideDivider,
    selected,
    asButton,
    href,
    base64Href,
    ocAriaLabel,
    iconLeft,
    iconRight,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );

  const slots = useSlots(Host);

  let divider = $derived(!hideDivider && variant !== "headline");
  let interactive = $derived(asButton || href || base64Href);
</script>

<InteractiveElement
  class={`row ${interactive ? "row--interactive" : ""} ${divider ? "row--divider" : ""} ${selected ? "row--selected" : ""}`}
  {asButton}
  bind:href
  {base64Href}
  {ocAriaLabel}
>
  {#if !variant || variant === "container"}
    <div class="container-wrapper">
      {#if iconLeft}
        <div class="left"><oc-icon-v1 size={"100"} type={iconLeft}></oc-icon-v1></div>
      {/if}
      <div class="content"><slot /></div>
      {#if iconRight}
        <div class="right"><oc-icon-v1 size={"100"} type={iconRight}></oc-icon-v1></div>
      {/if}
    </div>
  {:else if variant === "image"}
    <div class="image-wrapper">
      {#if slots.image}
        <div class="image"><slot name="image" /></div>
      {/if}
      <div class="content"><slot /></div>
      {#if iconRight}
        <div class="right"><oc-icon-v1 size={"100"} type={iconRight}></oc-icon-v1></div>
      {/if}
    </div>
  {:else if variant === "headline"}
    <h1 class="headline-wrapper"><slot /></h1>
  {:else if variant === "two-columns"}
    <dl class="two-columns-wrapper">
      {#if slots["column-label"]}
        <dt class="label"><slot name="column-label" /></dt>
      {/if}
      {#if slots["column-text"]}
        <dd class="text"><slot name="column-text" /></dd>
      {/if}
    </dl>
  {/if}
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  $image-spacing: tokens.$oc-semantic-spacing-25;
  $image-size: 40px;
  $headline-spacing-top: tokens.$oc-semantic-spacing-150;
  $headline-spacing-bottom: tokens.$oc-semantic-spacing-25;
  $spacing-x: tokens.$oc-semantic-spacing-100;
  $headline-font: tokens.$oc-semantic-font-copy-75;
  $headline-color: tokens.$oc-base-color-gray-400;

  .container-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    padding: tokens.$oc-component-row-spacing-y 0;
    gap: $spacing-x;

    .left,
    .right {
      line-height: 0;
    }

    .content {
      flex-grow: 1;
      text-align: left;
    }
  }

  .image-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    gap: $spacing-x;
    container .image {
      padding: $image-spacing 0;
      width: $image-size;
      height: $image-size;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .content {
      flex-grow: 1;
      text-align: left;
      padding: tokens.$oc-component-row-spacing-y 0;
    }

    .right {
      line-height: 0;
      padding: tokens.$oc-component-row-spacing-y 0;
    }
  }

  .headline-wrapper {
    width: 100%;
    margin: 0;
    padding: $headline-spacing-top 0 $headline-spacing-bottom;
    font: $headline-font;
    color: $headline-color;
    text-transform: uppercase;
  }

  .two-columns-wrapper {
    margin: 0;
    width: 100%;
    display: flex;
    gap: $spacing-x;
    padding: tokens.$oc-component-row-spacing-y 0;

    .label {
      font-weight: 700;
      flex: 1;
    }

    .text {
      margin: 0;
      flex: 1;
    }
  }

  .row {
    display: block;
    position: relative;
    width: 100%;
    padding: 0;
    margin: 0;
    text-decoration: none;
    text-align: left;
    border: none;
    background-color: tokens.$oc-component-row-background-color;
    font: tokens.$oc-semantic-font-copy-100;
    color: tokens.$oc-semantic-color-text-default;

    &--divider {
      margin-top: calc(-0.5 * tokens.$oc-component-row-border-width);
      border-top: tokens.$oc-component-row-border-width solid tokens.$oc-component-row-border-color;
    }

    &--selected {
      background-color: tokens.$oc-component-row-background-color-selected;
      font-weight: 700;
    }

    &--interactive {
      @include mixins.no-tap-highlight();

      cursor: pointer;

      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-row-background-color-hover;
        }
      }

      &:active {
        background-color: tokens.$oc-component-row-background-color-active;
      }

      &:after {
        z-index: 9999;
        content: "";
        position: absolute;
        inset: 0;
      }

      & {
        @include mixins.focus-styles(0.01px, "&:after");
      }
    }

    &.row--interactive.row--selected {
      @media (hover: hover) {
        &:hover {
          background-color: tokens.$oc-component-row-background-color-selected-hover;
        }
      }

      &:active {
        background-color: tokens.$oc-component-row-background-color-selected-active;
      }
    }
  }
</style>

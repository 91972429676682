<svelte:options
  customElement={{
    tag: "oc-dropdown-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true, formAssociated: true }),
    props: {
      label: { type: "String" },
      name: { type: "String", reflect: true }, /*                                    */
      value: { type: "String" }, /*                                     */
      options: { type: "Array" },
      hint: { type: "String" },
      validationMessage: { type: "String", attribute: "validation-message" },
      variant: { type: "String" },
      required: { type: "Boolean" },
      disabled: { type: "Boolean", reflect: true }, /*                                    */
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
      emptyOption: { type: "Boolean", attribute: "empty-option" },
    },
  }}
/>

<script lang="ts">
  import { onMount } from "svelte";

  import {
    ariaDescriptionWithValidation,
    implicitSubmit,
    refireNonComposableNativeEvent,
  } from "../../../common/actions";
  import { FormLabelIconV1 } from "../../../common/components/FormLabelIcon";
  import HintV1 from "../../../common/components/HintV1.svelte";
  import ValidationMessageV1 from "../../../common/components/ValidationMessageV1.svelte";

  import type { Props } from "./DropdownV1.types";

  let {
    label = undefined,
    name = undefined,
    variant = "default",
    hint = undefined,
    validationMessage = undefined,
    hideDetails = false,
    value = "",
    required = false,
    disabled = false,
    options = [],
    ocAriaLabel = undefined,
    emptyOption = false,
    internals,
  }: Props & {
    internals: ElementInternals;
    hideDetails?: boolean;
  } = $props();

  const Host = $host();

  export function resetForm() {
    /*                            */
    value = Host.getAttribute("value") || "";
  }

  let hasDetails = $derived(validationMessage || hint);

  let computedVariant = $derived(validationMessage ? "error" : variant);

  let selectElement = $state<HTMLElement>();

  let optionsFromSlot: typeof options = $state([]);

  const updateOptionsFromSlot = () => {
    optionsFromSlot = Array.from(Host.children)
      .filter((node): node is HTMLOptionElement => node instanceof HTMLOptionElement)
      .map((option) => ({
        label: option.label,
        value: option.value,
      }));
  };

  /*                               */
  let allOptions = $derived([...options, ...optionsFromSlot]);

  /*                                                          */
  onMount(() => {
    updateOptionsFromSlot();
    new MutationObserver(updateOptionsFromSlot).observe(Host, { childList: true });
  });

  /*                                                 */
  $effect(() => {
    if (selectElement) {
      const isValueInOptions = allOptions.some((option) => option.value === value);
      internals.setFormValue(isValueInOptions ? value : "");
    }
  });
</script>

<div
  class="dropdown"
  class:dropdown--disabled={disabled}
  class:dropdown--error={computedVariant === "error"}
  class:dropdown--selected={value}
>
  {#if label}
    <label for="dropdown" class="dropdown__label">
      {label}
      <FormLabelIconV1 variant={computedVariant} />
    </label>
  {/if}

  <select
    aria-label={ocAriaLabel}
    bind:this={selectElement}
    use:refireNonComposableNativeEvent={Host}
    use:implicitSubmit={internals}
    use:ariaDescriptionWithValidation={{ validationMessage, hint }}
    class="dropdown__select"
    {disabled}
    id="dropdown"
    bind:value
    aria-required={required}
  >
    {#if emptyOption}
      <option aria-label="Keine Auswahl"></option>
    {/if}

    {#each allOptions as option (option.value)}
      <option value={option.value}>{option.label}</option>
    {/each}
  </select>

  <div class="dropdown__arrow"></div>

  {#if !hideDetails && hasDetails}
    <ValidationMessageV1 {validationMessage} />
    <HintV1 {hint} {disabled} />
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  :host {
    @include mixins.no-tap-highlight();
    display: block;
  }

  .dropdown {
    box-sizing: border-box;
    position: relative;

    &__select {
      all: unset;
      width: 100%;
      display: block;
      box-sizing: border-box;
      padding: tokens.$oc-component-form-field-spacing-y 2rem
        tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;
      font: tokens.$oc-component-form-field-input-font;
      color: tokens.$oc-component-form-field-input-color;
      text-overflow: ellipsis;
      white-space: nowrap;
      background: tokens.$oc-component-form-background-color;
      outline: 1px solid tokens.$oc-component-form-default-border-color;
      border-radius: tokens.$oc-component-form-field-border-radius;

      &:hover {
        outline: 2px solid tokens.$oc-component-form-default-border-color;
      }

      &:focus {
        outline: 2px solid tokens.$oc-component-form-default-border-color-focus;
      }
    }

    &__arrow {
      position: absolute;
      top: 15px;
      right: 12px;
      width: 0.75rem;
      height: 0.75rem;
      margin-top: 0.25rem; /*                                                  */
      mask-image: url("/assets-static/icons/pl_icon_arrow-down50.svg");
      background-color: tokens.$oc-component-form-default-icon-color;
      pointer-events: none;
    }

    &__label {
      position: absolute;
      top: 0;
      left: 0;
      padding: tokens.$oc-component-form-field-spacing-y 2rem
        tokens.$oc-component-form-field-spacing-y tokens.$oc-component-form-field-spacing-x;
      max-width: 100%;
      box-sizing: border-box;
      font: tokens.$oc-component-form-label-font;
      color: tokens.$oc-component-form-default-label-color;

      pointer-events: none;
      background-color: tokens.$oc-component-form-background-color;
      border-radius: tokens.$oc-component-form-field-border-radius;
      transition: all 0.1s cubic-bezier(0.4, 0, 0.2, 1);

      /*                            */
      display: flex;
      align-items: center;
      gap: tokens.$oc-component-form-state-icon-gap-x;

      > slot {
        display: block;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
        cursor: text;
      }
    }

    &--selected {
      .dropdown__label {
        top: -0.5rem;
        left: tokens.$oc-component-form-field-floating-label-spacing-left;
        padding: 0 tokens.$oc-component-form-field-floating-label-inner-spacing-x;
        font: tokens.$oc-component-form-field-floating-label-font;
        border-radius: tokens.$oc-component-form-field-label-border-radius;

        /*                                                             */
        gap: 4px;
      }
    }

    &--error {
      .dropdown__label {
        color: tokens.$oc-component-form-error-label-color;
      }

      .dropdown__select {
        color: tokens.$oc-component-form-error-label-color;
        outline-color: tokens.$oc-component-form-error-border-color;

        &:hover {
          outline-color: tokens.$oc-component-form-error-border-color;
        }

        &:focus {
          outline-color: tokens.$oc-component-form-error-border-color;
        }
      }

      .dropdown__arrow {
        background-color: tokens.$oc-component-form-error-icon-color;
      }
    }

    &--disabled {
      .dropdown__label {
        color: tokens.$oc-component-form-disabled-label-color;
        border-color: tokens.$oc-component-form-disabled-border-color;
        background-color: tokens.$oc-component-form-disabled-background-color;
      }

      .dropdown__select {
        color: tokens.$oc-component-form-disabled-label-color;
        outline-color: tokens.$oc-component-form-disabled-border-color;
        background-color: tokens.$oc-component-form-disabled-background-color;

        &:hover {
          outline: 1px solid tokens.$oc-component-form-disabled-border-color;
        }
      }

      .dropdown__arrow {
        background-color: tokens.$oc-component-form-disabled-icon-color;
      }
    }
  }

  .dropdown__details {
    display: grid;
    grid-column-gap: 16px;
    margin-top: tokens.$oc-component-form-hint-gap-y;
    width: 100%;
  }
</style>

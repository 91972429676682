<svelte:options
  customElement={{
    tag: "oc-link-v2",
    /*                       */
    extend: window.__components.extend(),
    props: {
      variant: { type: "String" },
      size: { type: "String", reflect: true },
      href: { type: "String" },
      base64Href: { type: "String", attribute: "base64-href" },
      asButton: { type: "Boolean", attribute: "as-button" },
      target: { type: "String" },
      rel: { type: "String" },
      ocAriaLabel: { type: "String", attribute: "oc-aria-label" },
    },
  }}
/>

<script lang="ts">
  import { useFullPathHrefAccessor } from "@otto-ec/otto-components-utils/use/full-path-href-accessor";
  import { InteractiveElement } from "../../../common/components/InteractiveElement";

  import type { Props } from "./LinkV2.types.js";

  let {
    variant = "primary",
    size = "100",
    href = undefined,
    base64Href = undefined,
    asButton = false,
    target = undefined,
    ocAriaLabel = undefined,
    rel = undefined,
  }: Props = $props();

  const Host = $host<HTMLElement>();

  let lightDomLink = $state<HTMLElement | undefined>();

  const onslotchange = (ev: Event) => {
    const slot = ev.currentTarget as HTMLSlotElement;
    /*                                      */
    lightDomLink = slot
      .assignedNodes()
      .filter((n): n is HTMLElement => n.nodeType === Node.ELEMENT_NODE)
      .find(({ tagName }) => tagName === "A");
  };

  useFullPathHrefAccessor(
    Host,
    () => href,
    (v) => {
      href = v;
    },
  );
</script>

<InteractiveElement
  {asButton}
  bind:href
  {base64Href}
  {target}
  {rel}
  {lightDomLink}
  class={`link link--${variant}`}
  interactiveClass="link--interactive"
  aria-label={ocAriaLabel}
  data-oc-floating-focus-v1-target
>
  <slot {onslotchange} />
</InteractiveElement>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  /*                                  */
  /*                                                                                */
  @mixin expand-click-area() {
    &::before {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
    }
  }

  /*                                                                                              */
  :host {
    font: tokens.$oc-component-link-100-font;
    position: relative;
  }

  :host([size="inherit"]) {
    font: inherit;
  }

  :host([size="50"]) {
    font: tokens.$oc-component-link-50-font;
  }

  :host([size="75"]) {
    font: tokens.$oc-component-link-75-font;
  }

  :host([size="125"]) {
    font: tokens.$oc-component-link-125-font;
  }

  ::slotted(a) {
    @include expand-click-area();
    text-decoration: inherit;
    color: inherit;
  }

  .link {
    /*                    */
    display: inline;
    cursor: pointer;

    background: none;
    border: none;
    padding: 0;

    box-sizing: border-box;
    text-decoration: tokens.$oc-component-link-primary-text-decoration;
    color: tokens.$oc-component-link-primary-text-color;
    font: inherit;

    outline: none;

    & {
      @include mixins.no-tap-highlight();
    }

    &--primary {
      color: tokens.$oc-component-link-primary-text-color;
    }

    &--secondary {
      color: tokens.$oc-component-link-secondary-text-color;
    }

    &--underlined,
    &--underlined-bold {
      color: tokens.$oc-semantic-color-text-default;
      text-decoration: tokens.$oc-component-link-underlined-text-decoration;
    }

    &--underlined-bold {
      font-weight: tokens.$oc-component-link-underlined-bold-font-weight;
    }

    &--inverted,
    &--inverted-bold {
      color: tokens.$oc-semantic-color-text-inverted;
      text-decoration: tokens.$oc-component-link-inverted-text-decoration;
    }

    &--inverted-bold {
      font-weight: tokens.$oc-component-link-inverted-bold-font-weight;
    }

    &--interactive {
      @include expand-click-area();
    }
  }

  @media (hover: hover) {
    :host(:hover:not(:disabled)) .link {
      &--primary {
        text-decoration: tokens.$oc-component-link-primary-text-decoration-hover;
        color: tokens.$oc-component-link-primary-text-color-hover;
      }
      &--secondary {
        text-decoration: tokens.$oc-component-link-secondary-text-decoration-hover;
        color: tokens.$oc-component-link-secondary-text-color-hover;
      }
      &--underlined,
      &--underlined-bold {
        opacity: tokens.$oc-component-link-underlined-opacity-hover;
      }

      &--inverted,
      &--inverted-bold {
        opacity: tokens.$oc-component-link-inverted-opacity-hover;
      }
    }
  }

  :host(:active:not(:disabled)) .link {
    &--primary {
      text-decoration: tokens.$oc-component-link-primary-text-decoration-hover;
      color: tokens.$oc-component-link-primary-text-color-active;
    }
    &--secondary {
      text-decoration: tokens.$oc-component-link-secondary-text-decoration-hover;
      color: tokens.$oc-component-link-secondary-text-color-active;
    }
    &--underlined,
    &--underlined-bold {
      opacity: tokens.$oc-component-link-underlined-opacity-active;
    }

    &--inverted,
    &--inverted-bold {
      opacity: tokens.$oc-component-link-inverted-opacity-active;
    }
  }
</style>

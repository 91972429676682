import {
  type AsyncNamespaceInput,
  type FunctionImplementation,
} from "@otto-ec/global-resources/nexus";
import type { SnackbarV1 } from "./index.js";
import type { Props } from "./SnackbarV1.types.js";

function applyProps(snackbar: HTMLOcSnackbarV1Element, config: Partial<Props>): void {
  Object.assign(snackbar, config);

  /*                             */
  snackbar.open ??= true;
}

export const openSnackbar = function openSnackbar(id: string) {
  const snackbar = document.getElementById(id) as HTMLOcSnackbarV1Element | null;

  if (snackbar) {
    snackbar.open = true;
  }
} satisfies FunctionImplementation<SnackbarV1["open"]>;

export const closeSnackbar = function closeSnackbar(id: string) {
  const snackbar = document.getElementById(id) as HTMLOcSnackbarV1Element | null;

  if (snackbar) {
    snackbar.open = false;
  }
} satisfies FunctionImplementation<SnackbarV1["close"]>;

export const closeAllSnackbars = function closeAllSnackbars() {
  const snackbars = document.querySelectorAll("oc-snackbar-v1");

  snackbars.forEach((snackbar) => {
    snackbar.open = false;
  });
} satisfies FunctionImplementation<SnackbarV1["closeAll"]>;

export const create = function create(config = {}) {
  const snackbar = document.createElement("oc-snackbar-v1") as HTMLOcSnackbarV1Element;

  /*                    */
  applyProps(snackbar, config);

  document.body.append(snackbar);
  return snackbar;
} satisfies FunctionImplementation<SnackbarV1["create"]>;

/*                                                                               */
export const snackbarV1: AsyncNamespaceInput<OttoComponentApi["snackbarV1"]> = {
  open: openSnackbar,
  close: closeSnackbar,
  closeAll: closeAllSnackbars,
  create,
};

<svelte:options
  customElement={{
    tag: "oc-snackbar-v1",
    /*                                            */
    extend: window.__components.extend(),
    props: {
      open: { type: "Boolean" },
      message: { type: "String" },
      id: { type: "String", reflect: true },
      forceReplaceExistingInstance: {
        type: "Boolean",
        attribute: "force-replace-existing-instance",
      },
    },
  }}
/>

<script lang="ts">
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { nanoid } from "@otto-ec/global-resources/misc";
  import { srSpeak } from "@otto-ec/global-resources/accesibility";
  import { onDestroy } from "svelte";
  import { useSlots } from "../../../common/utils/useSlots.svelte";
  import type { Events, Props } from "./SnackbarV1.types.js";

  let {
    open = false,
    message,
    id = `id-${nanoid()}`,
    forceReplaceExistingInstance = false,
  }: Props = $props();

  const Host = $host();
  const slots = useSlots(Host);
  const dispatch = useEventDispatcher<Events>(Host);

  let isOpen = $state(open);

  $effect(() => {
    if (open === isOpen) return;

    /*                                             */
    /*                                                     */
    if (!dispatch(open ? "oc-open" : "oc-close")) {
      open = isOpen;
      return;
    }

    isOpen = open;
  });

  $effect(() => {
    if (isOpen) {
      srSpeak(message);
    }
  });

  function handleClose() {
    open = false;
  }

  function handleESCKey(event: KeyboardEvent): void {
    if (event.code === "Escape") {
      handleClose();
    }
  }

  /*                                                                                      */
  /*                                         */
  /*                                                                       */
  if (forceReplaceExistingInstance) {
    document.querySelectorAll(`[id="${id}"]`).forEach((el) => el.remove());
  }

  /*                                                                                              */
  /*                                                                                                       */
  if (import.meta.env.STORYBOOK) {
    /*                                                                                    */
    onDestroy(handleClose);
  } else {
    document.body.append(Host);
  }
</script>

<svelte:document onkeydown={handleESCKey} />

<div class="snackbar" class:snackbar--open={isOpen} class:snackbar--has-action={slots.actions}>
  <div class="snackbar__body">
    {message}
  </div>

  {#if slots.actions}
    <div class="snackbar__actions">
      <slot name="actions" />
    </div>
  {/if}

  <!-- svelte-ignore a11y_no_static_element_interactions -->
  <!-- svelte-ignore a11y_click_events_have_key_events -->
  <oc-icon-button-v2
    class="snackbar__close-button"
    variant="inverted-transparent"
    icon="close"
    size={"50"}
    onclick={handleClose}
    ocAriaLabel="schließen"
    title="Snackbar schließen"
  ></oc-icon-button-v2>
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;
  @use "@otto-ec/otto-components-utils/scss/mixins";

  .snackbar {
    align-items: center;
    background-color: tokens.$oc-component-snackbar-background-color;
    border-radius: tokens.$oc-semantic-border-radius-50; /*                            */
    bottom: tokens.$oc-component-snackbar-outer-spacing-bottom;
    display: flex;
    flex-wrap: wrap;
    gap: tokens.$oc-component-snackbar-text-action-gap-y
      tokens.$oc-component-snackbar-text-action-min-gap-x;
    left: 50%;
    min-height: 32px;
    padding-bottom: tokens.$oc-component-snackbar-spacing-y;
    padding-left: tokens.$oc-component-snackbar-spacing-left;
    padding-right: 56px;
    padding-top: tokens.$oc-component-snackbar-spacing-y;
    position: fixed;
    /*                                                     */
    width: calc(100vw - tokens.$oc-component-snackbar-outer-spacing-x * 2 - 48px);
    z-index: 9999;

    &--has-action {
      padding-right: 48px;
    }

    &__body {
      color: tokens.$oc-component-snackbar-copy-color;
      font: tokens.$oc-component-snackbar-copy-font; /*                         */
      line-height: tokens.$oc-base-font-line-height-150;
    }

    &__close-button {
      position: absolute;
      top: tokens.$oc-semantic-spacing-50;
      right: tokens.$oc-semantic-spacing-50;
      padding: 0;
      background: none;
      border: none;
    }
  }

  .snackbar {
    transition: all tokens.$oc-component-snackbar-disappear-duration
      tokens.$oc-component-snackbar-disappear-easing;
    opacity: 0;
    transform: translate(-50%, 100%);
  }

  .snackbar--open {
    transition: all tokens.$oc-component-snackbar-appear-duration
      tokens.$oc-component-snackbar-appear-easing;
    opacity: 1;
    transform: translate(-50%, 0);
  }

  @include mixins.breakpoint-from-m(false, false) {
    .snackbar {
      max-width: max-content;
    }
  }
</style>

import { defineEvent } from "@otto-ec/global-resources/nexus";
import type { AsyncNamespaceInput, FunctionImplementation } from "@otto-ec/global-resources/nexus";
import { get } from "svelte/store";
import { delegate } from "@otto-ec/global-resources/event";
import { parseParameters } from "@otto-ec/global-resources/attribute-parser";
import * as getters from "./lib/getters";
import type { SheetV1 } from "./index.js";
import type { OcSheetV1Props } from "./SheetV1.types.g";
import { getSheetElement } from "./lib/getters";
import { back, openSheet, next, close } from "./lib/sheetHistory";
import { applyProps } from "./lib/utils";

const TAG_NAME = "oc-sheet-v1";

const API_PREFIX = `data-${TAG_NAME}`;

const TRIGGER_OPEN = "open";

const TRIGGER_CREATE = "create";

const EVENT_CLICK = "click";

const DATA_SHEET_OPEN = `${API_PREFIX}-${TRIGGER_OPEN}`;

const DATA_SHEET_CREATE = `${API_PREFIX}-${TRIGGER_CREATE}`;

/**
 *
 *
 */
const DATA_SHEET_OPEN_LEGACY = "data-oc-sheet-id";

type AsSelector<T extends string> = `[${T}]`;

/**
 *
 *
 */
const DATA_SELECTORS = ([DATA_SHEET_OPEN, DATA_SHEET_CREATE, DATA_SHEET_OPEN_LEGACY] as const).map(
  (s) => `[${s}]` as const,
) as [
  AsSelector<typeof DATA_SHEET_OPEN>,
  AsSelector<typeof DATA_SHEET_CREATE>,
  AsSelector<typeof DATA_SHEET_OPEN_LEGACY>,
];

/**
 *
 */
export const create = function create(
  config = {},
  clickedElement: HTMLElement | undefined = undefined,
) {
  /*                                             */
  let sheetElement = config.id ? getSheetElement(config.id) : null;
  if (!sheetElement) {
    sheetElement = document.createElement(TAG_NAME);
  }

  /*                                              */
  const { dataSet = {}, ...conf } = config;

  /*                                             */
  applyProps(sheetElement, conf);
  Object.entries(dataSet).forEach(([key, value]) => {
    sheetElement.dataset[key] = value;
  });

  /*                                                                    */
  /*                                                                                      */
  /*                                    */
  if (import.meta.env.STORYBOOK && clickedElement) {
    clickedElement.insertAdjacentElement("afterend", sheetElement);
    return sheetElement;
  }

  document.body.append(sheetElement as Node);
  sheetElement.open ??= true;

  return sheetElement;
} satisfies FunctionImplementation<SheetV1["create"]>;

/*                                                                               */
export const sheetV1: AsyncNamespaceInput<OttoComponentApi["sheetV1"]> = {
  open: next,
  close,
  back,
  create,
  getOpenSheet: () => get(openSheet),
  getContent: () => getters.getContent(get(openSheet)),
  getHeader: () => getters.getHeader(get(openSheet)),
  getActions: () => getters.getActions(get(openSheet)),

  events: {
    beforeOpen: defineEvent(),
    open: defineEvent(),
    afterOpen: defineEvent(),

    beforeClose: defineEvent(),
    close: defineEvent(),
    afterClose: defineEvent(),

    beforeContentChange: defineEvent(),
    contentChangeShow: defineEvent(),
    contentChangeHide: defineEvent(),

    contentLoaded: defineEvent(),
    contentLoadingError: defineEvent(),

    switch: defineEvent(),
  },

  beforeOpenEvent: defineEvent(),
  openEvent: defineEvent(),
  afterOpenEvent: defineEvent(),

  beforeCloseEvent: defineEvent(),
  closeEvent: defineEvent(),
  afterCloseEvent: defineEvent(),
  switchEvent: defineEvent(),

  beforeContentChangeEvent: defineEvent(),
  contentChangeShowEvent: defineEvent(),
  contentChangeHideEvent: defineEvent(),

  contentLoadedEvent: defineEvent(),
  contentLoadingErrorEvent: defineEvent(),
};

/**
 *
 *
 */
function handleOpenOnClick(event: MouseEvent): void {
  const clickedElement = event.currentTarget as HTMLElement;
  const disabled = clickedElement.getAttribute("disabled");
  if (disabled) {
    return;
  }

  const sheetID = clickedElement.getAttribute(DATA_SHEET_OPEN_LEGACY);
  if (sheetID) {
    /*                            */
    next(sheetID);
    return;
  }

  const config = parseParameters<OcSheetV1Props>(API_PREFIX, TRIGGER_OPEN, clickedElement);
  if (config.id) {
    next(config.id, config);
  }
}

/**
 *
 *
 */
export function handleCreateOnClick(event: MouseEvent): void {
  const clickedElement = event.currentTarget as HTMLElement;
  const config = parseParameters<OcSheetV1Props>(API_PREFIX, TRIGGER_CREATE, clickedElement);

  const sheetElement = create(config, clickedElement);
  sheetElement.open = true;

  /*                                                                                           */
  clickedElement.setAttribute(`${DATA_SHEET_CREATE}.id`, sheetElement.id);
}

delegate(window.document, EVENT_CLICK, DATA_SELECTORS[0], handleOpenOnClick);
delegate(window.document, EVENT_CLICK, DATA_SELECTORS[2], handleOpenOnClick);
delegate(window.document, EVENT_CLICK, DATA_SELECTORS[1], handleCreateOnClick);

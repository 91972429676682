<svelte:options
  customElement={{
    tag: "oc-banner-v1",
    shadow: "none",
    /*                                            */
    extend: window.__components.extend({ delegateFocus: true }),
    props: {
      headline: { type: "String" },
      hideCloseButton: { type: "Boolean", attribute: "hide-close-button" },
      hideIcon: { type: "Boolean", attribute: "hide-icon" },
      iconType: { type: "String", attribute: "icon-type" },
      size: { type: "String" },
      variant: { type: "String" },
      hidden: { type: "Boolean", reflect: true },
    },
  }}
/>

<script lang="ts">
  import { useEventDispatcher } from "@otto-ec/otto-components-utils/use/event-dispatcher";
  import { iconTypeByBannerType } from "./consts";
  import type { Events, Props } from "./BannerV1.types.js";
  import { useSlots } from "../../../common/utils/useSlots.svelte";

  let {
    headline = undefined,
    hideCloseButton = false,
    hideIcon = false,
    iconType = undefined,
    size = "100",
    variant = "info",
    hidden = false,
  }: Props = $props();

  const Host = $host();

  const slots = useSlots(Host);

  let computedIconType = $derived(iconType || iconTypeByBannerType[variant]);

  const dispatch = useEventDispatcher<Events>(Host);

  export function handleClose() {
    dispatch("oc-close");
    hidden = true;
  }
</script>

<div
  class="banner {`banner--type-${variant}`} {`banner--size-${size}`}"
  class:banner--no-icon={hideIcon}
  class:banner--hide-close-button={hideCloseButton}
>
  {#if !hideIcon}
    <oc-icon-v1 class="banner__icon" type={computedIconType}></oc-icon-v1>
  {/if}

  {#if headline && size !== "100"}
    <h1 class="banner__headline">{headline}</h1>
  {/if}

  <div class="banner__body">
    <slot />
  </div>

  {#if slots.actions}
    <div class="banner__actions">
      <slot name="actions" />
    </div>
  {/if}

  {#if !hideCloseButton}
    <!-- svelte-ignore a11y_no_static_element_interactions -->
    <!-- svelte-ignore a11y_click_events_have_key_events -->
    <oc-icon-button-v2
      class="banner__close-button"
      icon="close"
      size={"50"}
      variant="transparent"
      onclick={handleClose}
      ocAriaLabel="schließen"
      title="Hinweis schließen"
    ></oc-icon-button-v2>
  {/if}
</div>

<style lang="scss" global>
  @use "@otto-ec/design-tokens/component" as tokens;

  :host(:not([hidden])) {
    display: block;
  }

  .banner {
    display: block;
    position: relative;
    padding: tokens.$oc-component-banner-100-spacing;
    padding-left: 52px;
    border-radius: tokens.$oc-component-banner-border-radius;
    min-height: 24px;
    background-color: tokens.$oc-component-banner-info-background-color;

    &__icon {
      position: absolute;
      top: 16px;
      left: 16px;
    }

    &__headline {
      font: tokens.$oc-component-banner-info-headline-color;
      margin-block-start: 0;
      margin-block-end: 0;
    }

    &__body {
      margin-right: 36px;
      font: tokens.$oc-component-banner-copy-font;
      color: tokens.$oc-component-banner-copy-color;
    }

    &__actions {
      display: flex;
      gap: tokens.$oc-component-banner-100-gap-x;
      margin-top: tokens.$oc-component-banner-100-gap-y;
    }

    &__close-button {
      position: absolute;
      top: 12px;
      right: 12px;
      padding: 0;
      background: none;
      border: none;
    }

    &--type-error {
      background-color: tokens.$oc-component-banner-error-background-color;

      .banner__headline {
        color: tokens.$oc-component-banner-error-headline-color;
      }

      .banner__icon {
        --color: #{tokens.$oc-component-banner-error-icon-color};
      }
    }

    &--type-success {
      background-color: tokens.$oc-component-banner-success-background-color;

      .banner__headline {
        color: tokens.$oc-component-banner-success-headline-color;
      }

      .banner__icon {
        --color: #{tokens.$oc-component-banner-success-icon-color};
      }
    }

    &--type-warning {
      background-color: tokens.$oc-component-banner-warning-background-color;

      .banner__headline {
        color: tokens.$oc-component-banner-warning-headline-color;
      }

      .banner__icon {
        --color: #{tokens.$oc-component-banner-warning-icon-color};
      }
    }

    &--type-hint {
      background-color: tokens.$oc-component-banner-hint-background-color;

      .banner__headline {
        color: tokens.$oc-component-banner-hint-headline-color;
      }

      .banner__icon {
        --color: #{tokens.$oc-component-banner-hint-icon-color};
      }
    }

    &--type-info {
      background-color: tokens.$oc-component-banner-info-background-color;

      .banner__headline {
        color: tokens.$oc-component-banner-info-headline-color;
      }

      .banner__icon {
        --color: #{tokens.$oc-component-banner-info-icon-color};
      }
    }

    &--size-100 {
      .banner__icon {
        width: tokens.$oc-component-banner-100-icon-size;
        height: tokens.$oc-component-banner-100-icon-size;
      }
    }

    &--size-200 {
      .banner__headline {
        font: tokens.$oc-component-banner-200-headline-font;
        padding-bottom: tokens.$oc-component-banner-200-gap-y;
        margin-right: 36px;
      }

      .banner__actions {
        justify-content: flex-end;
      }

      .banner__body {
        margin-right: 0;
        padding-bottom: tokens.$oc-component-banner-200-text-spacing-bottom;
      }

      .banner__actions {
        gap: tokens.$oc-component-banner-200-gap-x;
      }

      .banner__icon {
        width: tokens.$oc-component-banner-200-icon-size;
        height: tokens.$oc-component-banner-200-icon-size;
      }
    }

    &--size-300 {
      padding: tokens.$oc-component-banner-300-spacing;
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: tokens.$oc-component-banner-300-gap-y;

      .banner__icon {
        position: static;
        width: tokens.$oc-component-banner-300-icon-size;
        height: tokens.$oc-component-banner-300-icon-size;
      }

      .banner__headline {
        font: tokens.$oc-component-banner-300-headline-font;
        text-align: center;
        padding-bottom: 0;
      }

      .banner__actions {
        width: 100%;
        justify-content: flex-end;
      }

      .banner__body {
        margin-right: 0;
        margin-bottom: tokens.$oc-component-banner-300-text-spacing-bottom;
      }

      .banner__actions {
        gap: tokens.$oc-component-banner-300-gap-x;
        margin-top: 0;
      }
    }

    &--no-icon {
      padding-left: 16px;
    }

    &--hide-close-button {
      padding-right: 16px;

      .banner__body {
        margin-right: 0;
      }

      .banner__headline {
        margin-right: 0;
      }
    }

    &--no-icon.banner--hide-close-button {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
</style>
